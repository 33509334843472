import React from 'react'

const Navbar = () => {
  return (
   <nav className='navbar navbar-dark bg-dark mb-5'>
   <span className="navbar-brand mb-0 h1 mx-auto">LyricFinder</span>

   </nav>
  )
}

export default Navbar