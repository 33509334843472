import React from 'react'
import AlbumInfo from '../tracks/AlbumInfo'
import Search from '../tracks/Search'
import Tracks from '../tracks/Tracks'

const Index = () => {
  return (
    <React.Fragment>
      <Search />
      <Tracks />
      <AlbumInfo />
    </React.Fragment>
  )
}

export default Index