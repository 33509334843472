import React, {useEffect, useState} from 'react'

const AlbumInfo = () => {
    
    const [image, setImage] = useState('We have something')
    const testLink = `http://localhost:8080/https://api.musixmatch.com/ws/1.1/chart.tracks.get?chart_name=top&page=1&page_size=10&country=us&f_has_lyrics=1&apikey=${process.env.REACT_APP_MM_KEY}`
    const realLink = `http://localhost:8080/https://api.musixmatch.com/ws/1.1/album.tracks.get?album_id=54497775&apikey=${process.env.REACT_APP_MM_KEY}`

    const getImage = async () => {
        try {
            const response = await fetch(realLink)
            const data = await response.json()
            console.log(data)
            setImage(data)

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getImage()
    }, [])

  return (
    <div>
    <h3>Image info</h3>
    </div>
  )
}

export default AlbumInfo