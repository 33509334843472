import React from 'react'
import spinner from './spinner.gif'

const Spinner = () => {
  return (
    <div>
        <img src={spinner} alt="Loading..."
        style={{ width: '200px', margin: '40px auto', display: 'block'}}
         />
         <h2>Have You ran Node Server.js for CORS yet?</h2>
    </div>
  )
}

export default Spinner